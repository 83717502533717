import React, {
    useEffect,
    useState,
    useContext,
    useCallback,
    useRef,
    useLayoutEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import _ from 'lodash';
import { withNamespaces } from 'react-i18next';
import appleIconSm from '@assets/pictures/svgs/appleIcon.svg';
import appleIconWhiteSm from '@assets/pictures/svgs/appleIconWhite.svg';
import '@src/assets/css/PaymentRedirect.css';
import { toast } from 'react-toastify';
import MovieService from '@apiService/MovieService';
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import logoepix from '@assets/pictures/epix-logo.png';

const PaymentRedirectV2 = () => {
    const { reservation_id = null, secretPayload = null } = useParams();
    const currCode = "BHD";
    const salt = 'AppPay'

    const decryptString = (encoded) => {
        const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
        const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded
            .match(/.{1,2}/g)
            .map((hex) => parseInt(hex, 16))
            .map(applySaltToChar)
            .map((charCode) => String.fromCharCode(charCode))
            .join("");
    };

    const [amount, setAmount] = useState(0);
    const [openPopUP, setOpenPopup] = useState(true);
    const [OverlayLoader, setOverlayLoader] = useState(false);

    useEffect(() => {
        if (openPopUP) {
            setOpenPopup(false);
            setTimeout(() => {
                if (window.ApplePaySession) {
                    // onApplePayButtonClicked();
                } else {
                    toast.error(`Apple Pay Not Supported.`);
                }
            }, 500);
        }
    }, [openPopUP])

    useEffect(() => {
        if (secretPayload) {
            let decryptPayload = decryptString(secretPayload);
            let tempPayload = JSON.parse(decryptPayload);
            console.log('decryptPayload', decryptPayload);
            console.log('decryptPayload', tempPayload && tempPayload.hasOwnProperty("amount") && tempPayload['amount']);
            if (tempPayload && tempPayload.hasOwnProperty("amount") && tempPayload['amount']) {
                setAmount(parseFloat(tempPayload['amount'] || 0).toFixed(2))
            } else {
                setAmount(parseFloat(0).toFixed(2))
            }
            // setTimeout(() => {
            //     if (window.ApplePaySession) {
            //         onApplePayButtonClicked();
            //     } else {
            //         toast.error(`Apple Pay Not Supported.`);
            //     }
            // }, 500);
        } else {
            setAmount(parseFloat(0).toFixed(2))
        }
    }, [reservation_id, secretPayload]);

    const InsertApplePayLogs = (message, action) => {
        let logPayload = {
            reservation_id: reservation_id,
            message: message || null,
            action: action || null,
        }
        MovieService.ApplePayLog(logPayload).then((response) => { }).catch((err) => console.log('InsertApplePayLogs Error:', err))
    }

    const onApplePayButtonClicked = async () => {
        setOverlayLoader(true);
        const paymentRequest = {
            countryCode: 'BH',
            currencyCode: 'BHD',
            lineItems: [
                {
                    label: "Epix Cinema Co",
                    amount: parseFloat(amount || 0).toFixed(2),
                },
            ],
            total: {
                label: 'Epix Cinema Co',
                amount: parseFloat(amount || 0).toFixed(2),
            },
            supportedNetworks: ['visa', 'masterCard'],
            merchantCapabilities: ['supports3DS'],
        };

        if (window.ApplePaySession) {
            console.log('THIS DEVICE SUPPOPRTS APPLE PAY', window.ApplePaySession);
            const session = new window.ApplePaySession(3, paymentRequest);
            console.log('NEW session', session);
            InsertApplePayLogs(session, "session")
            session.onvalidatemerchant = async (event) => {
                const validationURL = event.validationURL;

                console.log('onvalidatemerchant validationURL', validationURL);
                InsertApplePayLogs(validationURL, "VALIDATION URL")
                const response = await MovieService.ApplePayMerchantValidation({
                    validationURL: validationURL
                });
                const { data } = response;
                console.log('merchantSession', data);
                InsertApplePayLogs(data, "VALIDATION RESPONSE")
                session.completeMerchantValidation(data);
            };

            session.onpaymentauthorized = async (event) => {
                const payment = event.payment;
                console.log('onpaymentauthorizedToken', event.payment.token);
                InsertApplePayLogs({
                    token: event.payment.token
                }, "onpaymentauthorizedToken")
                InsertApplePayLogs({
                    status: window.ApplePaySession.STATUS_SUCCESS
                }, "onpaymentauthorizedStatus")
                // const paymentData = {
                //     transactionId: payment.token.transactionIdentifier,
                //     paymentMethod: payment.token.paymentMethod,
                //     paymentData: payment.token.paymentData,
                // };


                let encPayload = {
                    reservation_id: reservation_id,
                    pk_token: event.payment.token
                }

                InsertApplePayLogs(event.payment.token, "pk_token")

                const response = await MovieService.ApplePayPayment(encPayload);

                const { data } = response;

                let redirectUrl = ``

                if (data.hasOwnProperty('redirectUrl') && data.redirectUrl) {
                    redirectUrl = data.redirectUrl;
                }

                InsertApplePayLogs(data, "onpaymentauthorized RESPONSE")

                if (data.status) {
                    setOverlayLoader(false);
                    session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
                    if (redirectUrl) {
                        const newWindow = window.open(redirectUrl, '_self');
                        if (newWindow) newWindow.opener = null;
                    }
                } else {
                    setOverlayLoader(false);
                    session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                    if (redirectUrl) {
                        const newWindow = window.open(redirectUrl, '_self');
                        if (newWindow) newWindow.opener = null;
                    }
                }
            };

            session.begin();
        } else {
            toast.error(`Apple Pay Not Supported.`);
            setOverlayLoader(false);
            return false;
            const paymentData = {
                version: "EC_v1",
                data: "encryptedDataString",
                signature: "signatureString",
                header: {
                    ephemeralPublicKey: "ephemeralPublicKeyString",
                    publicKeyHash: "publicKeyHashString",
                    transactionId: "transactionIdString"
                }
            };
            let encPayload = {
                reservation_id: reservation_id,
                pk_token: JSON.stringify(paymentData)
            }
            const response = await MovieService.ApplePayPayment(encPayload);

            const { data } = response;

            let redirectUrl = ``

            if (data.hasOwnProperty('redirectUrl') && data.redirectUrl) {
                redirectUrl = data.redirectUrl;
            }

            if (data.status) {
                setOverlayLoader(false);
                if (redirectUrl) {
                    const newWindow = window.open(redirectUrl, '_self');
                    if (newWindow) newWindow.opener = null;
                }
            } else {
                setOverlayLoader(false);
                if (redirectUrl) {
                    const newWindow = window.open(redirectUrl, '_self');
                    if (newWindow) newWindow.opener = null;
                }
            }
            InsertApplePayLogs('Apple Pay Not Supported', "onpaymentauthorized ELSE")
            // toast.error(`Apple Pay Not Supported.`);
            console.log('Apple Pay is not available on this device.');
        }
    };

    return (
        <>
            <CircleSpinnerOverlay
                loading={OverlayLoader}
                overlayColor="rgb(1 1 1 / 63%)"
            />
            <div className='apple-mainContainer'>
                <div className='appleWrapper'>
                    <div className='w-100'>
                        <div className="card">
                            <div className="mainCard-body">
                                <div className="mainCard-title">
                                    <img
                                        className="payment-multipage-img-mobile-style"
                                        height={50}
                                        width={90}
                                        src={logoepix}
                                    />
                                </div>
                                <div className='amtBox'>
                                    <div className='line1'>TOTAL</div>
                                    <div className='line2'>{currCode}&nbsp;{parseFloat(amount).toFixed(3)}</div>
                                </div>
                                <div className='apple-orderDetails'>
                                    <span className='line1'>Order No.</span>
                                    <span className='line2'>{reservation_id}</span>
                                </div>
                                <div className='btnWrapper d-flex justify-content-center align-items-center row'>
                                    <button className='payBtn col-lg-4 col-sm-9 col-9' onClick={() => onApplePayButtonClicked()}>
                                        <img
                                            className="payment-multipage-img-mobile-style"
                                            height={20}
                                            width={20}
                                            src={appleIconWhiteSm}
                                        />
                                        <span className='payText ml-1 font-weight-bold'>Pay</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default PaymentRedirectV2;